<template>
	<div>
		<swiper class="pt-md-1" id="menu-horizon" :options="swiperOption">
			<template v-if="action">
				<swiper-slide v-for="tab in tabsComputed" :key="tab.label">
					<a @click.stop.prevent="action(tab)" href="" :class="{ active: tab.active }">{{ $t(tab.label) }}</a>
				</swiper-slide>
			</template>
			<template v-else>
				<swiper-slide v-for="(tab, index) in tabsComputed" :key="tab.label" :class="`menu_${tab.href}`">
					<b-button v-show="tab.dropdown" v-b-toggle="'collapse-'+index" @click="selectFirstByDefault(tab.label)">{{ $t(tab.label) }}</b-button>
					<router-link v-show="!tab.dropdown" :to="{ name: tab.href, params: tab.params }" v-on:click.native="notifyClickSuperTab(tab)" title="" :class="{ active: tab.active }" replace > <font-awesome-icon v-if="tab.icon" :icon="tab.icon" /> {{ $t(tab.label) }}</router-link>
				</swiper-slide>
			</template>
		</swiper>
		<template v-for="(tab, index) in tabsComputed">
	        <b-collapse v-if="tab.dropdown" :ref="'collapse-'+index" :id="'collapse-'+index" :key="index">
	            <div class="container-fluid">
	                <div class="row">
	                    <template v-for="sub_tab in tab.dropdown">
	                    	<div v-if="!sub_tab.hidden" class="col-6 col-sm-auto mb-3" :key="sub_tab.label">
								<router-link :to="{ name: sub_tab.href, params: sub_tab.params }" v-on:click.native="notifyClickTab(tab)" title="" :class="{ active: sub_tab.active }" replace>{{ $t(sub_tab.label) }}</router-link>
							</div>
	                	</template>
	                </div>
	            </div>
	        </b-collapse>
	    </template>
    </div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import 'swiper/dist/css/swiper.css'
	import { swiper, swiperSlide } from 'vue-awesome-swiper'
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
        props: {
            action: { type: Function, default: null },
            tabs: { type : Array, default: () => [] }
        },
		data () {
			return {
                local_tabs: [],
				swiperOption: {
					slidesPerView: 'auto',
					spaceBetween: 0,
					preventClicks: false,
					preventClicksPropagation: false
				}
			}
        },
        created() {
            this.preinit_component()
        },
		mounted() {
            this.set_active_tab()
		},
		methods: {
            preinit_component() {
                this.local_tabs = _cloneDeep(this.tabs)
            },
			set_active_tab() {
				if(!this.local_tabs || this.local_tabs.length === 0)
					return false

				if(!this.local_tabs[0].href)
					return false

                const old_tab = this.local_tabs.find(tab => tab.active === true)
                if(old_tab) old_tab.active = false

                const route_name = this.$route.name
				const actual_tab = this.local_tabs.find(tab => (
					tab.href === route_name
					&& (
						tab.params ? JSON.stringify(tab.params) === JSON.stringify(this.$route.params) : true
					)
				))

                if(actual_tab) actual_tab.active = true
			},
			notifyClickSuperTab(tab) {
				for (let i in this.tabsComputed) {
					if(this.$refs["collapse-" + i] !== undefined && this.$refs["collapse-" + i][0] !== undefined) {
						this.$refs["collapse-" + i][0].$data.show = false
					}
				}
				this.notifyClickTab(tab)
			},
			notifyClickTab(tab) {
				EventBus.$emit("Swipper::TabClicked", tab)
			},
			selectFirstByDefault(label) {
				for (let i in this.tabsComputed) {
					if(label != this.tabsComputed[i] && this.$refs["collapse-" + i] !== undefined && this.$refs["collapse-" + i][0] !== undefined) {
						this.$refs["collapse-" + i][0].$data.show = false
					}
				}

				let actual_tab = this.local_tabs.filter(t => t.label == label)
				let tab = actual_tab[0].dropdown.find(tab => tab.default)
				if(tab) {
					this.$router.replace({name: tab.href, params: tab.params})
				} else {
					this.$router.replace({name: actual_tab[0].dropdown[0].href, params: actual_tab[0].dropdown[0].params})
				}
			}
		},
		computed: {
			tabsComputed() {
				return this.local_tabs ? this.local_tabs.filter(t => !t.hidden) : []
            }
		},
		watch: {
			'tabs': {
				handler(val) {
					this.local_tabs = val
				},
				deep: true
			},
			'$route' (_) {
                this.set_active_tab()
			}
		},
        components: {
            swiper,
            swiperSlide
        }
	}
</script>
